.MuiCardHeader-title {
  font-size: 14px !important;
  font-weight: bolder !important;
}

.MuiCardHeader-subheader {
  margin-top: 5px !important;
  font-size: 18px !important;
}

.avatar-card .MuiAvatar-img {
  width: 76% !important;
  height: auto;
}